import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from '../../utils/page.utils';
import { useStyles } from './FaceSwapTrimmingSlider.styles';
import clsx from 'clsx';

/**
 * FaceSwapTrimmingSlider는 세 개의 thumb를 가진 범위 슬라이더입니다.
 * value는 정렬되지 않은 배열(예: [left, mid, right])이어야 하며,
 * onChange와 onChangeCommitted 콜백을 통해 값을 외부로 전달합니다.
 */
const FaceSwapTrimmingSlider = ({ 
  value, 
  onChange, 
  onChangeCommitted,
  min, 
  max, 
  duration
}) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);
  const sliderRef = useRef(null);
  const [hoveredThumb, setHoveredThumb] = useState(null);
  const [draggingThumb, setDraggingThumb] = useState(null);
  const [trackHovered, setTrackHovered] = useState(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const percentFromValue = (val) => ((val - min) / (max - min)) * 100;
  const minGap = (max - min) / duration + 0.001;

  const handleTrackMouseEnter = () => setTrackHovered(true);
  const handleTrackMouseLeave = () => setTrackHovered(false);

  const handlePointerDown = (thumbIndex, event) => {
    event.preventDefault();
    setDraggingThumb(thumbIndex);
    sliderRef.current.setPointerCapture(event.pointerId);
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();

    const onPointerMove = (moveEvent) => {
      const x = moveEvent.clientX - rect.left;
      let newPercent = (x / rect.width) * 100;
      newPercent = Math.min(100, Math.max(0, newPercent));
      let newValue = min + ((max - min) * newPercent) / 100;

      if (thumbIndex === 0) {
        newValue = Math.min(newValue, internalValue[2] - minGap);
      }
      if (thumbIndex === 2) {
        newValue = Math.max(newValue, internalValue[0] + minGap);
      }

      let newValues = [...internalValue];
      newValues[thumbIndex] = newValue;

      // 밀어내기(pushable) 로직: (중앙 thumb에 대해서는 제한 없이, 드래그 thumb에 따라 인접 thumb도 같이 이동)
      if (thumbIndex === 0 && newValue > internalValue[1]) {
        newValues[1] = newValue;
        if (newValue > internalValue[2]) {
          newValues[2] = newValue;
        }
      }
      if (thumbIndex === 2 && newValue < internalValue[1]) {
        newValues[1] = newValue;
        if (newValue < internalValue[0]) {
          newValues[0] = newValue;
        }
      }
      if (thumbIndex === 1) {
        newValues[1] = Math.min(Math.max(newValue, internalValue[0]), internalValue[2]);
      }

      setInternalValue(newValues);
      if (onChange) {
        onChange(null, newValues, thumbIndex);
      }
    };

    const onPointerUp = (upEvent) => {
      slider.releasePointerCapture(upEvent.pointerId);
      slider.removeEventListener('pointermove', onPointerMove);
      slider.removeEventListener('pointerup', onPointerUp);
      setDraggingThumb(null);
      if (onChangeCommitted) {
        onChangeCommitted(null, internalValue);
      }
    };

    slider.addEventListener('pointermove', onPointerMove);
    slider.addEventListener('pointerup', onPointerUp);
  };

  const handleTrackClick = (event) => {
    event.stopPropagation();
    const slider = sliderRef.current;
    const rect = slider.getBoundingClientRect();
    const x = event.clientX - rect.left;
    let newPercent = (x / rect.width) * 100;
    newPercent = Math.min(100, Math.max(0, newPercent));
    const newValue = min + ((max - min) * newPercent) / 100;
    const newValues = [...internalValue];
    newValues[1] = newValue;
    setInternalValue(newValues);
    if (onChange) {
      onChange(event, newValues, 1);
    }
    if (onChangeCommitted) {
      onChangeCommitted(event, newValues);
    }
  };

  const startPercent = percentFromValue(internalValue[0]);
  const midPercent = percentFromValue(internalValue[1]);
  const endPercent = percentFromValue(internalValue[2]);

  const totalDurationLabel = formatTime(((internalValue[2] - internalValue[0]) / 100) * duration);
  const middleLabel = formatTime(((internalValue[1] - internalValue[0]) / 100) * duration);

  const centerThumbLabel =
    draggingThumb === 0 || draggingThumb === 2 ? totalDurationLabel : middleLabel;

  return (
    <div
      ref={sliderRef}
      className={classes.sliderRoot}
      onMouseEnter={handleTrackMouseEnter}
      onMouseLeave={handleTrackMouseLeave}
    >
      <div
        className={classes.sliderTrack}
        onClick={handleTrackClick}
        style={{
          left: `${startPercent}%`,
          width: `${endPercent - startPercent}%`,
        }}
      />
      {internalValue.map((val, index) => {
        let thumbClass = classes.sliderThumb;
        if (index === 0) thumbClass = clsx(thumbClass, classes.thumbLeft);
        else if (index === 1) thumbClass = clsx(thumbClass, classes.thumbMiddle);
        else if (index === 2) thumbClass = clsx(thumbClass, classes.thumbRight);

        return (
          <div
            key={index}
            className={thumbClass}
            style={{ left: `${percentFromValue(val)}%` }}
            onPointerDown={(e) => handlePointerDown(index, e)}
            onMouseEnter={() => setHoveredThumb(index)}
            onMouseLeave={() => setHoveredThumb(null)}
          >
            {index === 1 &&
              (hoveredThumb === 1 || draggingThumb === 1 || draggingThumb === 0 || draggingThumb === 2) && (
                <div className={classes.valueLabel}>{centerThumbLabel}</div>
              )}
          </div>
        );
      })}
    </div>
  );
};

FaceSwapTrimmingSlider.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  duration: PropTypes.number,
};

FaceSwapTrimmingSlider.defaultProps = {
  min: 0,
  max: 100,
  duration: 0,
};

export default FaceSwapTrimmingSlider;
