import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  settingsTitle: {
    fontFamily: 'TheJamsil',
    fontWeight: '400',
  },
  settingsSeparater: {
    borderBottomWidth: '1px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'solid',
    margin: '0.3rem 0 0.6rem 0',
    height: '0',
  },
  settingsSeparaterSub: {
    borderBottomWidth: '2px',
    borderBottomColor: '#A4A4A4',
    borderBottomStyle: 'dashed',
    margin: '-0.3rem 0 1rem 0',
    width: '100%',
    height: '0',
  },
  settingsInnerBoxOne: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #fff',
    borderRadius: '0.5rem',
    padding: '1rem',
    backgroundColor: '#161616',
  },
  settingsInnerBoxSub: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  settingsTextContainer: {
    display: 'flex',
    gap: '0.5rem',
    height: '1rem',
  },
  settingsText: {
    fontFamily: 'TheJamsil',
    fontSize: '0.75rem',
  },
  settingsRatio: {
    color: '#fff',
    fontSize: '0.65rem',
    fontFamily: 'TheJamsil',
    border: '1.5px solid #6F6F6F',
    borderRadius: '0.25rem',
    padding: '0.3rem',
    display: 'flex',
    alignItems: 'center',
  },
  settingsTextFieldInputProps: {
    textAlign: 'right',
    color: '#fff',
    fontSize: '0.75rem',
    fontFamily: 'TheJamsil',
    padding: '0',
    margin: '0',
  },
  settingsTextField: {
    color: '#fff',
    borderBottom: '1.5px solid #6F6F6F',
    margin: '0',
    padding: '0',
    width: '1rem',
    height: '1rem',
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield', // Firefox
    },
  },
  settingsRoot: {
    color: '#fff',
    marginBotton: '-1rem',
    width: '95%',
    marginTop: '0.5rem',
    marginBottom: '-0.5rem',
    '& .MuiSlider-thumb': {
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      backgroundColor: '#fff',
      boxShadow: '0 0 0.5rem 0 rgba(1, 1, 1, 0.40)',
      zIndex: '5',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-track': {
      border: '1px solid white',
      backgroundColor: '#fff',
      zIndex: '3',
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#fff',
      zIndex: '1',
    },
    '& .MuiSlider-mark': {
      width: '0.7rem',
      height: '0.7rem',
      borderRadius: '50%',
      backgroundColor: 'grey',
      marginLeft: '-0.25rem',
      zIndex: '2',
    },
  },
  settingsInnerBoxTwo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #CAFF73',
    borderRadius: '0.5rem',
    color: '#CAFF73',
    padding: '1rem',
    backgroundColor: '#161616',
  },
  settingsBtnContainer: {
    width: '100%',
    display: 'flex',
    gap: '0.6rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  applyButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    width: '100%',
    height: '2.4rem',
    marginTop: '1rem',
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    textTransform: 'initial',
    fontWeight: 'normal',
    backgroundColor: '#fff',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0',
    color: '#000',
  },
  startButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    width: '9rem',
    height: '2.4rem',
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    textTransform: 'initial',
    fontWeight: 'normal',
    backgroundColor: '#CAFF73',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0',
    color: '#000',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  mobileApplyButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
    width: '9rem',
    height: '2.4rem',
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    textTransform: 'initial',
    fontWeight: 'normal',
    backgroundColor: '#fff',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0',
    color: '#000',
  },
  upgradeButton: {
    width: '9rem',
    height: '2.4rem',
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    textTransform: 'initial',
    fontWeight: 'normal',
    backgroundColor: '#CAFF73',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0',
    color: '#000',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  redCoin: {
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    color: '#ff0000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  coin: {
    fontFamily: 'TheJamsil',
    fontSize: '0.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tooltipImg: {
    width: '0.74rem',
    height: '0.9rem',
    marginLeft: '0.3rem',
  },

  upgradeDialog: {
    fontFamily: 'TheJamsil',
    '& .MuiPaper-root': {
      backgroundColor: '#1E1E1E',
      padding: '1.2rem 1.5rem',
    },
  },
  dialogTitle: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '0.9rem',
    paddingTop: '0.5rem',
  },
  dialogText: {
    color: '#AAAAAA',
    textAlign: 'center',
    fontWeight: 300,
    fontSize: '0.8rem',
    paddingTop: '0.6rem',
    maxWidth: '18rem',
  },
  dialogButton: {
    width: 'fit-content',
    padding: '0.6rem 1rem',
    fontSize: '0.8rem',
    backgroundColor: '#CAFF73',
    borderRadius: '0.2rem',
    cursor: 'pointer',
    margin: '0 auto',
    marginTop: '1.5rem',
  },
}));

const SettingsSwitch = styled(Switch)(() => ({
  padding: 8,
  marginRight: '-1.5rem',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: '#6F6F6F',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#CAFF73',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
}));

export { useStyles, SettingsSwitch };
