import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  sliderRoot: {
    [theme.breakpoints.down('xs')]: {
      height: '1.6rem',
    },
    position: 'relative',
    height: '2rem',
    width: '100%',
    backgroundColor: '#434343',
    padding: '0 0.3rem',
    boxSizing: 'content-box',
    color: '#CAFF73',
    display: 'flex',
    alignItems: 'center',
    touchAction: 'none',
  },
  sliderTrack: {
    [theme.breakpoints.down('xs')]: {
      height: '1.9rem',
    },
    position: 'absolute',
    height: '2.3rem',
    borderRadius: '0',
    border: 'none',
    backgroundColor: '#434343',
    borderBottomWidth: '2px',
    borderBottomColor: '#fff',
    borderBottomStyle: 'solid',
    borderTopWidth: '2px',
    borderTopColor: '#fff',
    borderTopStyle: 'solid',
    padding: '0 0.3rem',
    marginLeft: '-0.3rem',
    boxSizing: 'content-box',
  },
  sliderThumb: {
    [theme.breakpoints.down('xs')]: {
      height: '2.2rem',
    },
    position: 'absolute',
    zIndex: 10,
    marginTop: '0',
    height: '2.6rem',
    backgroundColor: '#fff',
    boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.40)',
    cursor: 'pointer',
    transform: 'translateX(-50%)',
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
  },
  thumbLeft: {
    [theme.breakpoints.down('xs')]: {
      width: '0.6rem',
      border: '0.25rem solid #fff',
      borderTop: '0.65rem solid #fff',
      borderBottom: '0.65rem solid #fff',
      marginLeft: '-0.3rem',
    },
    width: '0.8rem',
    backgroundColor: '#3F3F3F',
    border: '0.35rem solid #fff',
    borderTop: '0.75rem solid #fff',
    borderBottom: '0.75rem solid #fff',
    borderRadius: '0.3rem 0 0 0.3rem',
    marginLeft: '-0.7rem',
  },
  thumbMiddle: {
    [theme.breakpoints.down('xs')]: {
      height: '2.6rem',
      width: '0.4rem',
    },
    height: '3rem',
    width: '0.6rem',
    backgroundColor: '#fff',
    borderRadius: '1rem',
    marginLeft: '0',
  },
  thumbRight: {
    [theme.breakpoints.down('xs')]: {
      width: '0.6rem',
      border: '0.25rem solid #fff',
      borderTop: '0.65rem solid #fff',
      borderBottom: '0.65rem solid #fff',
      marginLeft: '0.3rem',
    },
    width: '0.8rem',
    backgroundColor: '#3F3F3F',
    border: '0.35rem solid #fff',
    borderTop: '0.75rem solid #fff',
    borderBottom: '0.75rem solid #fff',
    borderRadius: '0 0.3rem 0.3rem 0',
    marginLeft: '0.7rem',
  },
  valueLabel: {
    position: 'absolute',
    top: '-30px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '4px 8px',
    backgroundColor: '#CAFF73',
    color: '#000',
    fontSize: '0.8rem',
    borderRadius: '3px',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
  },
}));

export { useStyles };